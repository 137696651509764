/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import ml5 from "ml5";
import config from "./config";

export function WhichDogIdentifier() {
  // Stages: 'waiting', 'loading', 'labeled'
  const [stage, setStage] = useState("waiting");
  const [fileUrl, setFileUrl] = useState(null);
  const [label, setLabel] = useState(null);
  const [isError, setIsError] = useState(false);
  const imageRef = useRef();

  useEffect(() => {
    async function predict() {
      // I shouldn't be reloading the model on every image!
      // But it seems to get "stuck" otherwise and it's pretty quick.
      try {
        const m = await ml5.imageClassifier(config.url + "model.json");
        const image = new Image();
        image.src = fileUrl;
        m.classify(image, (error, results) => {
          if (error) {
            console.error(error);
            return;
          }
          setLabel(results[0]);
          console.log(results);
          setStage("labeled");
        });
      } catch {
        setIsError(true);
        console.error("Error on creating model, image, or prediction.");
      }
    }

    if (fileUrl) {
      predict();
    }
  }, [fileUrl, setLabel, setIsError]);

  return (
    <div>
      {isError && (
        <div class="error">
          😿 Dog detection is broken. Maybe try refreshing?
        </div>
      )}
      <div class="image-label-container">
        {stage !== "waiting" && <img src={fileUrl} ref={imageRef} />}
        {stage === "labeled" &&
          (label.confidence > 0.95 ? (
            <div class="label">It's {label.label}</div>
          ) : (
            <div class="low-confidence">
              This is neither
              <br />
              this nor that dog.
            </div>
          ))}
      </div>
      {stage === "loading" && <div class="thinking">🤔</div>}

      <div class="uploader">
        <label class="custom-uploader" for="image-upload">
          {stage === "waiting" ? "Add a dog" : "Different dog"}
        </label>
        <input
          type="file"
          id="image-upload"
          accept="image/*"
          onChange={(event) => {
            if (event.target.files.length > 0) {
              setStage("loading");
              setFileUrl(URL.createObjectURL(event.target.files[0]));
            }
          }}
        />
      </div>
    </div>
  );
}
