import "./App.css";
import { WhichDogIdentifier } from "./WhichDogIdentifier";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Is it <strong>this</strong> dog
          <br />
          or is it <strong>that</strong> dog?
        </h1>
      </header>
      <WhichDogIdentifier />
    </div>
  );
}

export default App;
